<template>
  
  </template>
  
  <script>
  import {
    BContainer,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BButton,
  } from 'bootstrap-vue';
  
  export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BCard,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BFormDatepicker,
      BButton,
    },
    data() {
      return {
        form: {
          name: '',
          customerLead: '',
          status: null,
          salesStage: null,
          salesPerson: null,
          amount: null,
          openDate: null,
          closeDate: null,
          daysInFunnel: null,
        },
        statusOptions: ['New', 'In Progress', 'Won', 'Lost'],
        salesStageOptions: ['Qualification', 'Proposal', 'Negotiation', 'Closing'],
        salesPersonOptions: [
          { value: 'Salesperson A', text: 'Salesperson A' },
          { value: 'Salesperson B', text: 'Salesperson B' },
          { value: 'Salesperson C', text: 'Salesperson C' },
          // Add more sales persons as needed
        ],
      };
    },
    methods: {
      onSubmit() {
        // Implement submission logic here
        console.log('Form submitted:', this.form);
      },
    },
  };
  </script>
  